.register__card__container {
	height: 650px;
	width: 420px;
	margin: auto;
	box-shadow: 0px 2px 5px -2px;
}
.input-box,.input-box-file {
	width: 100%;
	margin-top: 30px;
	display: inline-grid;
	position: relative;
	}
	.input-boxx {
	width: 100%;
	margin-top: 35px; 
	position: relative;
	display: grid;
	}
 
.input-box p{
	color: red;
	font-size: 14px;
	
}
.register__card {
	height: 100%;
	width: 90%;
	margin: auto;
	padding: 10px;
}
.login-box img {
	display: block;
	margin: auto;
	margin-bottom: 20px !important;
	}
.gender input{
	column-gap: 5px;
}
.input-boxx label{
	font-size: 17px;
	font-weight: 600;
	color: #000;
}

.input-box label{
	font-size: 17px;
	font-weight: 501;
	color: #000;
}

.input-box-file label{
	font-size: 17px;
	font-weight: 501;
	color: #000;
}
.gender label{
	color: #333;
  font-weight: 500;
  margin: 0 10px;
}


select[name='country']{
	appearance: none;
}

.input-boxx input { 
	margin-top: 8px;  
}
.input-bo{
	display: -webkit-inline-box;
	margin: 15px auto;

}
.input-boxx input{
	width: fit-content;
	background-color: #fff;
	color: #707070;
	margin-top: 8px;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 10px 15px;
}
 .input-bo input{
	margin: 0px 15px 0px 0px ;
	width: 20px;
	height: 18px;
 }
.input-box input, select, textarea {
	margin: 0;
	margin-top: 0px;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	position: relative;
	height: 50px;
	width: 100%;
	outline: none;
	font-size: 1rem;
	color: #0b0b0b;
	margin-top: 8px;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 0 15px;
	background-color: #fff;
	}
	.login__auth__container{

	background-image: url(/public/assets/images/backgrounds/login.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	}
	.register__auth__container{
	background-image: url(/public/assets/images/backgrounds/login.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	}

.login-box {box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	padding: 40px;
	margin: auto;
	width: 75%;
	background: #ffffffdb;
	border-radius: 20px;
	opacity: 55;
	

	}
	.login-box h1 {
	text-align: center;
	font-size: 30px;
	border-bottom: 5px double #16cd05 ;
	width: fit-content;
	margin: auto;
	padding-bottom: 8px;
	color: #373995;
	}
.register__header {
	height: 20%;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}


.register__header h1 {
	font-size: 2.5rem;
	font-weight: bolder;

}

.register__inputs {
	height: 70%;
	width: 100%;
}

.reg__input__container {
	height:19%;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.input__label {
	height: 40%;
	width: 100%;
	font-size: 1.1rem;
	font-weight: 500;
}
.login-btn{
	border: 1px solid #e4e4e4;
	margin: auto;
	width: 100%;
	background-image: linear-gradient(to right, rgb(47 49 145), rgb(28 177 34));
	color: #fff;
	margin: 50px auto 20px auto;
	padding:10px 30px !important;
	border-radius: 40px;
}
.Forgot_Password{
	font-size: 16px;
	margin-top: 30px;
	font-weight: 501;
	color: rgb(47 49 145);
}
.register__input {
	width: 100%;
	height: 50%;
	border: 1px solid black;
	border-radius: 15px;
}

.register__button__container {
	height: 25%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.register__button {
	height: 50px;
	width: 100%;
	font-size: 1.1rem;
	background-color: #2f3191;
	border-color: #2f3191;
	border-style: dashed;
	font-weight:bold;
	color: #ffffff;
	border-radius: 10px;
}
.CreateAccount a {
	font-size: 17px;
	font-weight: 501;
	color: #2f3191;
	}
.register__other__actions {
	height: 20%;
	width: 100%;
}
.check_name {
  display: inline-flex;
}




